body {
  margin: 0;
  padding: 0 0 2.5rem 0;
  color: #333333;
  background-color: #fff;
}

h3 {
  margin: 0;
}

@media print {
  [aria-describedby="client-snackbar"] {
    display: none !important;
  }
}
